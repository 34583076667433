import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

const MetaRedirect = ({ url }) => {
  const { i18n } = useTranslation()
  const currentLang = i18n.language

  const seo = {
    url: url,
  }

  return (
    <Helmet htmlAttributes={{ lang: currentLang }}>
      <meta http-equiv="refresh" content={`0;url=${seo.url}`} />
    </Helmet>
  )
}

export default MetaRedirect
