import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* Components */
import MetaRedirect from "../components/meta-redirect/meta-redirect"

export default function Redirect({ location, data }) {
  const DocumentUrl = data.markdownRemark.frontmatter.url

  useEffect(() => {
    window.location = DocumentUrl
  }, [DocumentUrl])

  return (
    <>
      <MetaRedirect url={DocumentUrl} />
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        url
      }
    }
  }
`
